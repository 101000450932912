import getData from "@/__main__/get-data.mjs";
import { LeaderboardModel } from "@/data-models/cs2-leaderboard.mjs";
import { getLeaderboard } from "@/game-csgo/api.mjs";
import { devError } from "@/util/dev.mjs";

function fetchLeaderboard(_queryParams, searchParams) {
  const seasonId = searchParams.get("seasonId") || "1";
  const selected_region = searchParams.get("region") || "global";
  const region = selected_region.toUpperCase().replace(/-/g, "");

  return getData(
    getLeaderboard({ seasonId, region }),
    LeaderboardModel,
    ["cs2", "leaderboard", "premier", selected_region],
    {
      networkBackOffTime: 4 * 60 * 60 * 1000, // 4 hrs
    },
  ).catch((error) => {
    devError("Error loading leaderboard data", error);
  });
}

export default fetchLeaderboard;
